import {Outlet} from "react-router-dom"
import * as React from "react"
import {SimpleAppBar} from "./index"
import {AppLayoutType} from "../type-defs"
import {ContactUs} from "../components"

export default function AppLayout({children}: AppLayoutType) {
    return (
        <>
            <SimpleAppBar />
            <div className='py-5'>
                {children}
                <Outlet />
            </div>
            <ContactUs />
        </>
    );
}
