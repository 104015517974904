import * as React from "react"

export default function SimpleAppBar() {
    return (
        <>
            <header className='mb-3'>
                <nav className="navbar navbar-expand-lg fixed-top mask-custom shadow-0">
                    <div className="container">
                        <span className="navbar-brand" id='Brand'>
                            <a href='/' style={{fontSize: 25,}} className='text-green'>
                                <img src='/logo192.png' alt="Logo Locaci" width={100} />
                                {/*<b>Locaci</b>*/}
                            </a>
                        </span>
                    </div>
                </nav>
            </header>
        </>
    )
}
