import * as React from "react";
import {AppLayout} from "../layouts";
import {Banner, Definitions, VehicleList} from "../components"

export default function HomeScreen() {

    return (
        <AppLayout>
            <Banner showBecomePartner={true} />
            <div className='container'>
                <VehicleList />
                <Definitions />
            </div>
        </AppLayout>
    );
}
