import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";

export default function BecomePartner() {
    return (
        <div className='d-flex justify-content-between p-2 align-items-center text-white mb-2'>
            <div className='px-3'>
                <FontAwesomeIcon className='fa-2x' icon={faHandshake} />
            </div>
            <div className='w-100'>
                <h6 className='font-weight-bold mb-0'>Devenez partenaire Locaci</h6>
                <div className='small'>Confiez-nous vos véhicules à louer et gagnez de l'argent.</div>
            </div>
        </div>
    )
}
