import * as React from "react";
import styles from  './Banner.module.css'
import {Link} from "react-router-dom";
import {BecomePartner} from "../index";

export default function Banner({showBecomePartner = false, image = 'driver.jpg'}: {showBecomePartner: boolean, image?: string}) {
    return (
        <>
            <section className={styles.banner} style={{height: 450, backgroundImage: `url(/${image})`}}>
                <h1 className='text-center text-uppercase px-4 font-weight-bold text-orange py-2'>
                    Votre véhicule de location en Côte d'Ivoire
                </h1>
            </section>

            {showBecomePartner && (
                <div className='d-flex justify-content-center py-1 bg-green'>
                    <Link to={'/become-partner'}>
                        <BecomePartner />
                    </Link>
                </div>
            )}
        </>
    )
}
