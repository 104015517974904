import * as React from "react";
import {_definitions} from "../data";
import {Definition} from "./index";

export default function Definitions() {
    return <div className='row my-4'>
        {_definitions.map((item: any, key: any) => (
            <Definition key={key}{...item}/>
        ))}
    </div>
}
