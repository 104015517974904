import * as React from "react";
import styles from './SearchForm.module.css'
import {_bodyCars, _boxes, _energies} from '../../data'
import CheckBox from "./CheckBox";

type SearchForm = {
    onSearched: (searchedInfo: {bodyCars: [], energies: [], boxes: [] }) => any,
}

export default function SearchForm({onSearched} : any) {
    const [bodyCars, setBodyCars] = React.useState(new Set)
    const [energies, setEnergies] = React.useState(new Set)
    const [boxes, setBoxes] = React.useState(new Set)
    const [searched, setSearched] = React.useState({
        body_cars: [],
        energies: [],
        boxes: [],
    })

    function handleBodyCarChecked(event: any) {
        const { value, checked } = event.target;
        setBodyCars((prevState):any => {
            const newData = new Set(prevState)
            if (checked) {
                newData.add(value);
            } else {
                newData.delete(value);
            }

            onSearched({body_cars: Array.from(newData), energies: Array.from(energies), boxes: Array.from(boxes)})

            return newData
        })


    }

    function handleEnergyChecked(event: any) {
        const { value, checked } = event.target;
        setEnergies((prevState):any => {
            const newData = new Set(prevState)
            if (checked) {
                newData.add(value);
            } else {
                newData.delete(value);
            }

            onSearched({energies: Array.from(newData), body_cars: Array.from(bodyCars), boxes: Array.from(boxes)})

            return newData
        })
    }

    function handleBoxesChecked(event: any) {
        const { value, checked } = event.target;
        setBoxes((prevState):any => {
            const newData = new Set(prevState)
            if (checked) {
                newData.add(value);
            } else {
                newData.delete(value);
            }

            onSearched({energies: Array.from(newData), body_cars: Array.from(bodyCars), boxes: Array.from(newData)})

            return newData
        })
    }

    return (
        <>
            <h6 className='text-center my-2 text-uppercase text-muted'>Sélectionnez une voiture de votre choix !</h6>
            <section className={styles.Filter}>
                <div className='d-flex'>

                    {_bodyCars.map(({id, title}: any) => (
                        <CheckBox
                            key={title}
                            id={title}
                            label={title}
                            value={title}
                            handleChecked={ event => handleBodyCarChecked(event) }
                        />
                    ))}

                    {_boxes.map(({id, title}: any) => (
                        <CheckBox
                            key={title}
                            id={title}
                            label={title}
                            value={title}
                            handleChecked={ event => handleBoxesChecked(event) }
                        />
                    ))}

                    {_energies.map(({id, title}: any) => (
                        <CheckBox
                            key={title}
                            id={title}
                            label={title}
                            value={title}
                            handleChecked={ event => handleEnergyChecked(event) }
                        />
                    ))}

                </div>
            </section>
        </>
    )
}
