import * as React from "react";
import {AppLayout} from "../layouts"
import {Banner, Definitions, MetaHeading, PartnerCard} from "../components"

export default function BecomePartnerScreen() {


    return (
        <AppLayout>
            <MetaHeading
                title='Devenir Partenaire Locaci'
                description='Découvrez de nouvelles opportunités pour maximiser vos revenus en nous confiant votre véhicule de location !'
                imageUrl='/driver.jpeg'
            />
            <Banner showBecomePartner={false} image='driver.jpg' />
            <br />
            <PartnerCard />

        </AppLayout>
    );
}
