import * as React from "react"
import {Routes, Route, useLocation, Navigate,} from "react-router-dom"
import AuthProvider, {useAuth} from "./contexts/authContext"

import {
    HomeScreen, VehicleScreen, BecomePartnerScreen
} from './screens'

export default function App() {

  return (
      <AuthProvider>
        <Routes>
            <Route path="/" element={<HomeScreen />}/>
            <Route path="v/:slug" element={<VehicleScreen />}/>
            <Route path="/become-partner" element={<BecomePartnerScreen />}/>
        </Routes>
      </AuthProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (! auth.logged) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

