import * as React from "react";
import {contactUs} from "../utils";

export default function PartnerCard() {
    const phone = contactUs()

    return (
        <div className='container'>
            <h1 className='h3'>Optimisez votre véhicule, maximisez vos gains !</h1>

            <div>
                Vous avez un véhicule qui passe plus de temps au garage qu'en route ? Ne le laissez plus dormir !
                Confiez-le à <b>LOCACI</b> et transformez chaque kilomètre en bénéfice. En rejoignant notre communauté de propriétaires, vous profitez d'une plateforme sécurisée, d'une visibilité optimale et d'une gestion simplifiée.
                Louez votre véhicule en toute sérénité et commencez à percevoir des revenus sans effort.
            </div>

            <p>
                Notre plateforme de location vous permet de gagner de l'argent en mettant votre véhicule entre les mains expertes de nos administrateurs.
                <br />
                Profitez de notre réseau de clients potentiels, de notre expertise en gestion de location, et laissez-nous nous occuper de tout, du début à la fin.
            </p>

            <section className='text-center'>

                <h2 className='h6 text-uppercase'>
                    Contactez-nous dès maintenant sur WhatsApp
                </h2>

                <p>Contactez-nous dès maintenant pour une prise en charge de votre véhicule.</p>

                <p className='text-center'>
                    <a href={`tel:${phone}`} className='d-block text-decoration-none text-dark'>
                        <img src='/whatsapp.png' alt="Logo Locabu" width={120} />
                        <br/>
                        <br/>
                        <strong className='text-uppercase'>Appelez-nous au {phone} !</strong>
                    </a>
                </p>

            </section>
        </div>
    );
}
